export function setLightTheme() {
  let link = document.querySelector('#theme-link')
  link.href = "/css/light-mode-theme.css"
  document.documentElement.setAttribute('data-prefers-color-scheme', 'light')
}

export function setDarkTheme() {
  let link = document.querySelector('#theme-link')
  link.href = "/css/dark-mode-theme.css"
  document.documentElement.setAttribute('data-prefers-color-scheme', 'dark')
}