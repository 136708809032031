export function getWindowConfig() {
  let windowWidth = window.innerWidth
  let windowHeight = window.innerHeight
  if (typeof windowWidth !== 'number') {
    if (document.compatMode === 'CSS1Compat') {
      windowWidth = document.documentElement.clientWidth
      windowHeight = document.documentElement.clientHeight
    } else {
      windowWidth = document.body.clientWidth
      windowHeight = window.body.clientHeight
    }
  }
  return {
    windowWidth: windowWidth,
    windowHeight: windowHeight,
  }
}

export function isSafari() {
  return (
    /(Safari|iPhone|iPad|iPod)/.test(navigator.userAgent) &&
    !/Chrome/.test(navigator.userAgent) &&
    !/BlackBerry/.test(navigator.platform) &&
    !/(quark|Quark|UCBrowser)/.test(navigator.userAgent)
  )
}

export function isMobile() {
  return isAndroid() || isIOS() || isWindowsPhone()
}

export function isMacOS() {
  return navigator.platform.toLowerCase().indexOf('mac') >= 0
}

export function isIOS() {
  const iDevices = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod']

  if (!!navigator.platform && iDevices.indexOf(navigator.platform) > -1) {
    return true
  }

  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
}

export function isAndroid() {
  const ua = navigator.userAgent.toLowerCase()
  return ua.indexOf('android') > -1
}

export function isWindowsPhone() {
  if (navigator.userAgent.match(/Windows Phone/i)) {
    return true
  }

  if (navigator.userAgent.match(/iemobile/i)) {
    return true
  }

  if (navigator.userAgent.match(/WPDesktop/i)) {
    return true
  }

  return false
}
export function isChrome() {
  return /(Chrome|chrome)/.test(navigator.userAgent) &&
  !/(quark|Quark|UCBrowser)/.test(navigator.userAgent)
}

export function isFirefox() {
  return /(Firefox|FxiOS)/.test(navigator.userAgent) &&
  !/(quark|Quark|UCBrowser)/.test(navigator.userAgent)
}

export function isUC() {
  return /(UCBrowser|ubrowser)/.test(navigator.userAgent)
}

export function isEnableMicrophone() {
  if (!isMobile()) return true
  //   ios  safari chrome firefox
  if (isChrome()) return true
  if (isFirefox()) return true
  if (isSafari()) return true
  return false
}

export async function readImageSize(file) {
  return new Promise((resolve, reject) => {
    let useBlob = false
    const reader = new FileReader()

    reader.addEventListener('load', function () {
      try {
        const image = new Image()
        image.addEventListener('load', function () {
          const { width, height } = image
          if (useBlob) {
            window.URL.revokeObjectURL(image.src)
          }
          resolve([width, height])
        })
        image.src = useBlob ? window.URL.createObjectURL(file) : reader.result
      } catch {
        reject()
      }
    })
    reader.readAsDataURL(file)
  })
}

export function getFitSize(width, height, maxWidth, maxHeight) {
  if (width <= maxWidth && height <= maxHeight){
    return { width, height }
  }
  if (width > height) {
    return { width: maxWidth, height: Math.round((height / width) * maxWidth) }
  } else {
    return { width: Math.round((width / height) * maxHeight), height: maxHeight }
  }
}


export function dayDiff (date1, date2) {return Math.ceil(Math.abs(date1.getTime() - date2.getTime()) / 86400000)} 



/**
 * JS 计算两个时间间隔多久（时分秒）
 * @param startTime "2019-10-23 15:27:23"
 * @param endTime "2019-10-23 15:27:55"
 * @return 1天2时3分5秒
 */
export function twoTimeInterval(startTime, endTime) {
  // 开始时间
  let d1 = startTime.replace(/\-/g, "/").split('.')[0];
  let date1 = new Date(d1);
 
  // 结束时间
  let d2 = endTime.replace(/\-/g, "/").split('.')[0];
  let date2 = new Date(d2);
 
  // 时间相差秒数
  let dateDiff = date2.getTime() - date1.getTime();
 
  // 计算出相差天数
  let days = Math.floor(dateDiff / (24 * 3600 * 1000));
 
  // 计算出小时数
  let residue1 = dateDiff % (24 * 3600 * 1000); // 计算天数后剩余的毫秒数
  let hours = Math.floor(residue1 / (3600 * 1000));
 
  // 计算相差分钟数
  let residue2 = residue1 % (3600 * 1000); // 计算小时数后剩余的毫秒数
  let minutes = Math.floor(residue2 / (60 * 1000));
 
  // 计算相差秒数
  let residue3 = residue2 % (60 * 1000); // 计算分钟数后剩余的毫秒数
  let seconds = Math.round(residue3 / 1000);
 
  let returnVal =
    ((days == 0) ? "" : days+"天") +
    ((hours == 0) ? "" : hours +"时") +
    ((minutes == 0) ? "" : minutes+"分") +
    ((seconds == 0) ? "" : seconds+"秒");
 
  return returnVal;
 
}
