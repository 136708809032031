Date.prototype.format = function (fmt) {
  var o = {
    'M+': this.getMonth() + 1, //月份
    'd+': this.getDate(), //日
    'h+': this.getHours(), //小时
    'm+': this.getMinutes(), //分
    's+': this.getSeconds(), //秒
    'q+': Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds(), //毫秒
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
    }
  }
  return fmt
}


// //使用方法
// var now = new Date();
 
// var year = now.format("yyyy");
// console.log(year);
// var month = now.format("MM");
// console.log(month);
// var day = now.format("dd");
// console.log(day);
 
// var date = now.format("yyyy-MM-dd");
// console.log(date);
// var date_1 = now.format("yyyy/MM/dd");
// console.log(date_1);
// var date_2 = now.format("yyyyMMdd");
// console.log(date_2);
 
// var date_time = now.format("yyyy-MM-dd hh:mm:ss");
// console.log(date_time);
// var date_time_1 = now.format("yyyy年MM月dd日 hh时mm分ss秒");
// console.log(date_time_1);
// var date_time_2 = now.format("yyyy-MM-dd hh:mm:ss.S");
// console.log(date_time_2);
 
// var simple_time = now.format("yyyy-M-d h:m:s.S");
// console.log(simple_time);